import styles from './Loading.module.scss'
import { Spin } from 'antd'
const Loading = () => {
    return (
        <div className={styles.loading}>
            <Spin/>
        </div>
    )
}


export default Loading