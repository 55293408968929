import { useHistory, useParams } from "react-router-dom";
import styles from './EditApartment.module.scss';
import Input from "../../../components/BaseComponents/Input/Input";
import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ApartmentQL from "../../../Graphql/Apartment/Apartment";
const EditApartment = () => {
    let {id} = useParams();
    const [state, setState] = useState({});
    const history = useHistory();
    const {data, loading, error} = useQuery(ApartmentQL.getById(id));
    const [updateApartment,{data: dataUpdate}] = useMutation(ApartmentQL.update());
    useEffect(() => {
        if(loading === false && data){
            setState(() => {
                return {
                    ...data.apartment_blocks_by_pk
                }
            })
        }
    },[data, id])

    const handleUpdate = async () => {
        const res = await updateApartment({
            variables: {
                id: state.id,
                manage_by: state.manage_by,
                name: state.name,
                owner_by: state.owner_by,
                avatar: state.avatar,
                apartment_number: state.apartment_number ? state.apartment_number : null,
                address: state.address,
            }
        })
        if (res.data) {
            history.goBack();
            message.success("Cập nhật thành công!")
        } else {
            message.error("Cập nhật thất bại!")
        }
    }
    const handleChange = ({ name, value }) => {
        setState((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      };

    const handleCancel = () => {
        history.goBack();
      };
    return(
        <>
      <h1 className={styles.title}>Cập nhật căn hộ</h1>
      <div className={styles.addContent}>
        <Input
          label="Tên căn hộ: "
          onChange={handleChange}
          type="text"
          required={true}
          name="name"
          value={state.name}
        />
        <Input
          label="Địa chỉ: "
          onChange={handleChange}
          type="text"
          required={true}
          name="address"
          value={state.address}
        />
        <Input
          label="Số căn hộ: "
          onChange={handleChange}
          type="number"
          name="apartment_number"
          value={state.apartment_number}
        />
        <Input
          label="Ảnh : "
          onChange={handleChange}
          type="text"
          name="avatar"
          value={state.avatar}
        />
        <Input
          label="Chủ sở hữu: "
          onChange={handleChange}
          type="text"
          name="owner_by"
          value={state.owner_by}
        />
        <Input
          label="Quản lí: "
          onChange={handleChange}
          type="text"
          name="manage_by"
          value={state.manage_by}
        />
        <div className={styles.btn}>
          <Button
            onClick={handleUpdate}
            type="primary"
            style={{ marginRight: "20px" }}
          >
            Cập nhật
          </Button>
          <Button onClick={handleCancel}>Hủy bỏ</Button>
        </div>
      </div>
    </>
    )
}

export default EditApartment