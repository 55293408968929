
import "./App.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Login from "./pages/Login";
import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import SideBar from "./components/SideBar/SideBar";
import ProtectedRouter from "./Routes/ProtectedRouter";

const { Header, Sider, Content } = Layout;
function App() {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" render={() => <Login />} />
        <Route
          path="/"
          render={() => (
            <Layout style={{ height:'100%' }}>
              <Sider style={{ minHeight:"1000px" }} trigger={null} collapsible collapsed={collapsed}>
                <div className="logo">500 AE ADMIN</div>
                <SideBar/>
              </Sider>
              <Layout className="site-layout">
                <Header style={{ padding: 0, background: colorBgContainer }}>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => setCollapsed(!collapsed),
                    }
                  )}
                </Header>
                <Content style={{ 
                  margin: "10px 6px",
                  padding: 10,
                  minHeight: 280,
                  background: colorBgContainer,
                 }}>
                    <ProtectedRouter/>  
                </Content>
              </Layout>
            </Layout>
          )}
        />
        
      </Switch>
    </BrowserRouter>
  );
}

export default App;
