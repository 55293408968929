import { gql } from "@apollo/client";

const ApartmentQL = {
  getList({ limit = null, offset = null, query = null }) {
    return gql`
        query MyQuery {
            apartment_blocks(order_by: {created_at: desc},limit: ${limit}, offset: ${offset}, ${query}) {
              address
              apartment_number
              id
              manage_by
              name
              owner_by
              avatar
            }
            apartment_blocks_aggregate {
              aggregate {
                count(columns: id)
              }
            }
          }
        `;
  },
  delete() {
    return gql`
      mutation MyMutation($id: String!) {
        delete_apartment_blocks(where: { id: { _eq: $id } }) {
          affected_rows
          returning {
            address
            apartment_number
            avatar
            code
            created_at
            deleted_at
            delivery_time
          }
        }
      }
    `;
  },
  addApartment() {
    return gql`
      mutation MyMutation(
        $id: String!
        $manage_by: String!
        $name: String!
        $owner_by: String!
        $avatar: String!
        $apartment_number: Int!
        $address: String!
        $code: String!
      ) {
        insert_apartment_blocks(
          objects: {
            id: $id
            manage_by: $manage_by
            name: $name
            owner_by: $owner_by
            avatar: $avatar
            apartment_number: $apartment_number
            address: $address
            code: $code
          }
        ) {
          affected_rows
          returning {
            address
            apartment_number
            avatar
            code
            id
            manage_by
            name
            owner_by
          }
        }
      }
    `;
  },
  getById(id){
    return gql `
    query MyQuery {
      apartment_blocks_by_pk(id: "${id}") {
        address
        apartment_number
        avatar
        id
        manage_by
        name
        owner_by
      }
    }`
  },

  update(){
    return gql`
    mutation MyMutation($id: String!, $address: String!, $apartment_number: Int!, $avatar: String!, $manage_by: String!, $name: String!, $owner_by: String!) {
      update_apartment_blocks(where: {id: {_eq: $id}}, _set: {address: $address, apartment_number: $apartment_number, avatar: $avatar, manage_by: $manage_by, name: $name, owner_by: $owner_by}) {
        affected_rows
        returning {
          address
          apartment_number
          avatar
          code
          id
          manage_by
          name
          owner_by
          status
        }
      }
    }    
    `
  }
};

export default ApartmentQL;
