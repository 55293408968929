import { useMutation, useQuery } from "@apollo/client";
import Page from "../../components/Page/Page";
import styles from "./Apartment.module.scss";
import ApartmentQL from "../../Graphql/Apartment/Apartment";
import { useEffect, useState } from "react";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/BaseComponents/Pagination";
import { message } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { client } from "../../Graphql/client";
const initialState = {
  limit: 10,
  total_count: 0,
  apartments: [],
};
const Apartment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(initialState);
  const { offset, limit } = usePagination({ limit: state.limit });
  const history = useHistory();
  const res = useQuery(
    ApartmentQL.getList({ limit: state.limit, offset: offset, query: "" })
  );
  const [deleteApartment] = useMutation(ApartmentQL.delete());

  useEffect(() => {
    try {
      if (res.data) {
        setState((prev) => {
          return {
            ...prev,
            apartments: res.data.apartment_blocks,
            total_count: res.data.apartment_blocks_aggregate.aggregate.count,
          };
        });
        setIsLoading(false);
      }
    } catch (error) {
      message.error("Có lỗi hệ thống xảy ra!");
    }
  }, [res]);

  const handleDelete = async (id) => {
    try {
      const res = await deleteApartment({ variables: { id: id } });
      if (res.data) {
        message.success("Xóa thành công!");
        return client.resetStore();
      } else {
        message.error("Có lỗi xảy ra!");
      }
    } catch (error) {
      message.error("Chung cư đang có dịch vụ hoạt động!");
    }
  };

  const handleEdit = (id) => {
    history.push(`/apartment/edit/${id}`);
  };

  return (
    <>
      <h1 className={styles.title}>Chung cư</h1>
      <div className={styles.addButton}>
        <NavLink className={styles.navLink} to="apartment/add">
          Tạo mới
        </NavLink>
      </div>
      <Page isLoading={isLoading}>
        <div className={styles.search}></div>
        <table className={styles.table}>
          <thead>
          <tr className={styles.headTable}>
            <th>Ảnh</th>
            <th>Tên chung cư</th>
            <th>Địa chỉ</th>
            <th>Số căn hộ</th>
            <th>Chủ nhân</th>
            <th>Quản lí</th>
            <th colSpan={2}>Action</th>
          </tr>
          </thead>
          <tbody>
          {state &&
            state.apartments &&
            state.apartments.map((apartment, key) => {
              return (
                <tr key={key}>
                  <td>
                    <img
                      className={styles.avatar}
                      src={`${apartment.avatar}`}
                    />
                  </td>
                  <td>{apartment.name}</td>
                  <td>{apartment.address}</td>
                  <td>{apartment.apartment_number}</td>
                  <td>{apartment.owner_by}</td>
                  <td>{apartment.manage_by}</td>
                  <td className={styles.actionIcon}>
                    <EditOutlined
                      onClick={() => handleEdit(apartment.id)}
                      style={{ color: "darkcyan" }}
                    />
                  </td>
                  <td className={styles.actionIcon}>
                    <DeleteOutlined
                      style={{ color: "red" }}
                      onClick={() => handleDelete(apartment.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination total={state.total_count} />
      </Page>
    </>
  );
};

export default Apartment;
