import AddApartment from "../pages/Apartment/AddApartment"
import Apartment from "../pages/Apartment"
import User from "../pages/User"
import EditApartment from "../pages/Apartment/EditApartment"
import {UserOutlined,QuestionCircleOutlined,BorderBottomOutlined,ShopOutlined, LogoutOutlined} from "@ant-design/icons"
import UserService from "../pages/UserService"
import ActionUserService from "../pages/UserService/ActionUserService"

const routers = [
    {
        name: 'Người dùng',
        key: 'User',
        path: '/user',
        icon: <UserOutlined />,
        component : User,
        exact: true,
        inSideBar: true
        
    },
    {
        name: 'Chung cư',
        key: 'Chung cư',
        path: '/apartment',
        icon: <BorderBottomOutlined />,
        component: Apartment,
        exact: true,
        inSideBar: true
    },
    {
        name: 'Add Apartment',
        key: 'Add Apartment',
        path: '/apartment/add',
        component: AddApartment,
        exact: true,
        inSideBar: false

    },
    {
        name: 'Edit Apartment',
        key: 'Edit Apartment',
        path: '/apartment/edit/:id',
        component: EditApartment,
        exact: true,
        inSideBar: false
    },
    {
        name: 'Dịch vụ',
        key: 'Dịch vụ',
        path: '/user_service',
        icon: <ShopOutlined /> ,
        component: UserService,
        exact: true,
        inSideBar: true
    },
    {
        name: 'Thêm dịch vụ',
        key: 'Thêm dịch vụ',
        path: '/user_service/:type/:id?',
        component: ActionUserService,
        exact: true,
        inSideBar: false
    },
]

export default routers