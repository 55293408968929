import { useEffect, useState } from 'react';
import styles from './Login.module.scss'
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleLogin = (e) => {
      e.preventDefault();
      if (username === "du" && password === "123456") {
        Cookies.set('x-hasura-key', 'QU55Y25WQ1RXbDc4OTJISktuag==',{ expires: 7 });
        history.push('/');
      }
      else{
        setError("Tài khoản hoặc mật khẩu không chính xác !")
      }
  }

  useEffect(() => {
    if (Cookies.get('x-hasura-key')) {
        history.push('/');
    }
  }, [history]);
  return (
    <div className={styles.login}>
      <div className={styles.overlay}></div>
      <div className={styles.header}>
        
      </div>
      <div className={styles.body}>
        <h1>500 Anh Em</h1>
        <p>LOGIN TO ADMIN DASHBOARD</p>
        <div className={styles.formLogin}>
          <form>
            <input
              onChange={(e) => setUserName(e.target.value)}
              value={username}
              type="text"
              placeholder="Username"
              name="email"
            />
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Password"
              name="password"
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input onClick={handleLogin} type="submit" value="LOGIN"/>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
