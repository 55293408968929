import { Menu } from "antd";
import {
    UserOutlined,LogoutOutlined
  } from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import routers from "../../Routes/routers";
import { memo, useEffect } from "react";
import Cookies from "js-cookie";

const SideBar = () => {
  const history = useHistory();
  const handelLogout = () => {
    Cookies.set('x-hasura-key', "");
  }
  useEffect(() => {
        if(!Cookies.get('x-hasura-key')){
            history.push('/login')
        }
  },[history])
  return (
    <>
    <Menu
      theme="dark"
      mode="inline"
    //   defaultSelectedKeys={["1"]}
      items={
        routers.map((router, key) => {
          if(router.inSideBar){
            return{
                key: key,
                icon: (
                    <NavLink to={router.path}>
                        {router.icon}
                    </NavLink>
                ),
                label: router.name
            }
          }
        })
      }
    />
    <Menu 
      theme="dark"
      mode="inline"
      items= {[
        {
              key: 'logout',
              path: '/logout',
              icon: (
                  <NavLink to="/login" onClick={handelLogout}>
                      <LogoutOutlined/>
                  </NavLink>
              ),
              label: "Đăng xuất"
        }
      ]}
    />
        
  </>
    
  );
};

export default memo(SideBar)
