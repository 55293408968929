import { gql } from "@apollo/client"


const UserServiceQL = {
    getList({ limit = null, offset = null, query = null }){
        return gql`
        query MyQuery {
            user_services(order_by: {created_at: desc},limit: ${limit}, offset: ${offset}, ${query}) {
              id
              status
              created_at
              apartment_block_id
              user_id
              topic_id
              apartment_block {
                name
              }
              user {
                name
                phone_number
              }
              topic {
                name
                icon
              }
            }
            user_services_aggregate {
                aggregate {
                  count(columns: id)
                }
            }
          }
        `
    },
    insert(){
        return gql`
        mutation MyMutation($apartment_block_id: String!,$id: String!, $user_id: String!, $topic_id : String!) {
            insert_user_services(objects: {apartment_block_id: $apartment_block_id, id: $id, user_id: $user_id, topic_id: $topic_id}) {
              affected_rows
              returning {
                apartment_block_id
                created_at
                deleted_at
                id
                status
                user_id
              }
            }
          }
        `
    },

    delete () {
      return gql`
      mutation MyMutation($id: String!) {
        delete_user_services(where: {id: {_eq: $id}}) {
          affected_rows
          returning {
            apartment_block_id
            id
            status
            topic_id
          }
        }
      }`
    },

    update () {
      return gql`
      mutation MyMutation($topic_id: String!, $user_id: String!, $apartment_block_id: String!, $id: String!) {
        update_user_services(where: {id: {_eq: $id}}, _set: {apartment_block_id: $apartment_block_id, topic_id: $topic_id, user_id: $user_id}) {
          affected_rows
          returning {
            id
            apartment_block_id
            user_id
            topic_id
          }
        }
      }
      `
    },

    getById (id) {
      return gql`
      query MyQuery {
        user_services_by_pk(id: "${id}") {
          apartment_block_id
          id
          topic_id
          user_id
        }
      }
      `
    }
}


export default UserServiceQL