import { gql } from "@apollo/client";

const UserGL = {
  getList({limit = null, offset= null,query = null}) {
    return (gql`
    query MyQuery {
      users(limit: ${limit}, offset: ${offset}, ${query}, order_by: {created_at: desc}) {
        id
        name
        phone_number
        email
        apartment
        apartment_block_id
        housing_type
        avatar
        apartment_block {
          name
        }
      }
      users_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }`)
  },

  delete () {
    return gql`
    mutation MyMutation($id: String!) {
      delete_users(where: {id: {_eq: $id}}) {
        affected_rows
        returning {
          id
          apartment
          avatar
          email
        }
      }
    }`
  }
  
  }


export default UserGL