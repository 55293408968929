import styles from "./Input.module.scss"

const Input = (props) => {
    const handleChange = (e) => {
        let name = props.name;
        let value = e.target.value;
        if(props.onChange){
            props.onChange({
                name : name,
                value: value
            })
        }   
    }
    
    return(
        <div className={styles.input}>
            <label>{props.label} {props.required && <span className={styles.requiredActive}>*</span>}</label>
            <br/>
            <input type={props.type} onChange={handleChange} name={props.name} value={props.value}/>
            {/* { props.error && props.required && 
                <p>Mục này là bắt buộc</p>
            } */}
        </div>
    )
}

export default Input