import React from 'react';
import { Pagination } from 'antd';
import styles from './Pagination.module.scss'
import usePagination from '../../../hooks/usePagination';

const CustomPagination = (props) => {
	const {offset, limit, changePage} = usePagination({limit: 10})
	
	return (
		<>
			<div className={styles.paginate}>
				<Pagination 
					current={Math.ceil(offset / limit) + 1}
					pageSize={10}
					total={props.total}
					onChange={changePage}
					showSizeChanger={false}
				/>
			</div>
			<div className="clear"></div>
		</>
	);
}

export default CustomPagination;