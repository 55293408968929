import { Switch,Route } from "react-router-dom"
import routers from "./routers"


const ProtectedRouter = () => {
    return(
        <Switch>
            {
                routers.map((router,key) => {
                    return (
                        <Route key={key} exact={router.exact} path={router.path} component={router.component}/>   
                    )   
                })
            } 
        </Switch>
    )
}

export default ProtectedRouter