import { Select } from "antd";
import { useEffect, useState } from "react";
import styles from "./CustomSelect.module.scss";

const CustomSelect = (props) => {
  const { label, name, onChange, options, keyLabel, keyValue, required, value } = props;
  const [optionList, setOptionList] = useState([]);

  const handleChange = (e) => {
    if(onChange){
        onChange({
            name: name,
            value: e
        })
    }
  }



  useEffect(()=> {
    if(keyLabel && keyValue && options && options.length > 0) {
        const dataLists = options.map((item) => ({label: item[keyLabel], value: item[keyValue]}));
        setOptionList(dataLists)
    }
    else{
        setOptionList(options)
    }
  },[keyLabel, keyValue, options])

  return (
    <div className={styles.customSelect}>
      <label className={styles.label}>{label}{required && <span className={styles.requiredActive}>*</span>}  : </label>
      <br/>
      <Select
        allowClear
        onChange={handleChange}
        fieldNames={name}
        className={styles.select}
        showSearch
        value={value ? value : ""}
        style={{ width: "80%" }}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={optionList}
      />
    </div>
  );
};

export default CustomSelect;
