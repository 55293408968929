import { useHistory, useParams } from "react-router-dom";
import CustomSelect from "../../../components/BaseComponents/CustomSelect/CustomSelect";
import styles from "./ActionUserService.module.scss";
import { Button, message } from "antd";
import { v4 as uuid } from "uuid";
import { useMutation, useQuery } from "@apollo/client";
import UserGL from "../../../Graphql/User/User";
import ApartmentQL from "../../../Graphql/Apartment/Apartment";
import { useState, useEffect } from "react";
import UserServiceQL from "../../../Graphql/UserService/UserService";
import { client } from "../../../Graphql/client";
import TopicQL from "../../../Graphql/Topic/Topic";
const initState = {
  user_id: "",
  apartment_block_id: "",
  topic_id: "",
};

const ActionUserService = () => {
  const history = useHistory();
  const [state, setState] = useState(initState);
  const { type, id } = useParams();

  const userService = useQuery(UserServiceQL.getById(id));
  const users = useQuery(
    UserGL.getList({ limit: null, offset: null, query: "" })
  );
  const apartments = useQuery(
    ApartmentQL.getList({ limit: null, offset: null, query: "" })
  );
  const topics = useQuery(TopicQL.getList());
  const [addUserService] = useMutation(UserServiceQL.insert());
  const [updateUserService] = useMutation(UserServiceQL.update());
  useEffect(() => {
    if (type === "add") {
      const unique_id = uuid();
      setState((prev) => {
        return {
          ...prev,
          id: unique_id,
        };
      });
    }
    if (type === "edit" && id && userService.data) {
      setState((prev) => {
        return {
          ...prev,
          id: userService.data.user_services_by_pk.id,
          topic_id: userService.data.user_services_by_pk.topic_id,
          user_id: userService.data.user_services_by_pk.user_id,
          apartment_block_id:
            userService.data.user_services_by_pk.apartment_block_id,
        };
      });
    }
  }, [
    addUserService.data,
    addUserService.error,
    addUserService.loading,
    userService.data,
    id,
    type,
  ]);

  const handleChange = ({ name, value }) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleAddUserService = async () => {
    try {
      const res = await addUserService({
        variables: {
          id: state.id,
          apartment_block_id: state.apartment_block_id,
          topic_id: state.topic_id,
          user_id: state.user_id,
        },
      });
      if (res.data) {
        client.resetStore();
        message.success("Thêm dịch vụ thành công!");
        handleCancel();
      } else {
        message.error("Có lỗi xảy ra!");
      }
    } catch (error) {
      message.error("Lỗi");
    }
  };
  const handleUpdateUserService = async () => {
    try {
      const res = await updateUserService({
        variables: {
          id: state.id,
          apartment_block_id: state.apartment_block_id,
          topic_id: state.topic_id,
          user_id: state.user_id,
        },
      });
      if (res.data) {
        client.resetStore();
        message.success("Cập nhật dịch vụ thành công!");
        handleCancel();
      } else {
        message.error("Có lỗi xảy ra!");
      }
    } catch (error) {
      message.error("Lỗi");
    }
  };
  const handleCancel = () => {
    history.goBack();
  };
  return (
    <>
      <h1 className={styles.title}>{type === 'add' ? "Tạo mới" : "Cập nhật"} dịch vụ</h1>
      <div className={styles.addContent}>
        <CustomSelect
          label="Loại dịch vụ"
          required={true}
          name="topic_id"
          onChange={handleChange}
          options={topics?.data?.topics}
          keyLabel="name"
          keyValue="id"
          value= {state.topic_id}
        />
        <CustomSelect
          label="Người nhận dịch vụ"
          required={true}
          name="user_id"
          onChange={handleChange}
          options={users?.data?.users}
          keyLabel="name"
          keyValue="id"
          value= {state.user_id}
        />
        <CustomSelect
          label="Thuộc chung cư"
          required={true}
          name="apartment_block_id"
          onChange={handleChange}
          options={apartments?.data?.apartment_blocks}
          keyLabel="name"
          keyValue="id"
          value= {state.apartment_block_id}
        />
        <div className={styles.btn}>
          <Button
            onClick={type === 'add' ? handleAddUserService : handleUpdateUserService}
            type="primary"
            style={{ marginRight: "20px" }}
          >
            {type === 'add' ? "Tạo mới" : "Cập nhật"}
          </Button>
          <Button onClick={handleCancel}>Hủy bỏ</Button>
        </div>
      </div>
    </>
  );
};

export default ActionUserService;
