import { gql } from "@apollo/client";

const TopicQL = {
    getList() {
        return gql`
        query MyQuery {
            topics {
              icon
              id
              name
            }
          }
        `
    }
}


export default TopicQL