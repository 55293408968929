
import { useEffect } from "react"
import Loading from "../Loading"
import { useHistory } from "react-router-dom"
import Cookies from "js-cookie"

const Page = (props) => {
    
    return(
        <div>
            {
                props.isLoading ? 
                <Loading/>
                :
                <>
                    {props.children}
                </>
            }
        </div>
    )
}

export default Page