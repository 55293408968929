import { useEffect, useState } from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons"
import styles from "./UserService.module.scss";
import usePagination from "../../hooks/usePagination";
import Page from "../../components/Page";
import { NavLink, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import UserServiceQL from "../../Graphql/UserService/UserService";
import Pagination from "../../components/BaseComponents/Pagination";
import dayjs from "dayjs";
import { client } from "../../Graphql/client";
import { message } from "antd";

const initialState = {
  limit: 10,
  total_count: 0,
  userServices: [],
};
const UserService = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(initialState);
  const { offset, limit } = usePagination({ limit: state.limit });
  
  const history = useHistory();
  const userServices = useQuery(
    UserServiceQL.getList({ limit: state.limit, offset: offset, query: "" })
  );

  const [deleteUserService] = useMutation(UserServiceQL.delete());
  useEffect(() => {
    if (userServices.data) {
      setState((prev) => {
        return {
          ...prev,
          userServices: userServices.data.user_services,
          total_count: userServices.data.user_services_aggregate.aggregate.count,
        };
      });
      setIsLoading(false);
    }
  }, [userServices.data]);
  const handleDeleteUserService = async (id) => {
    try {
      const res = await deleteUserService({variables: {id : id}});
      if(res.data){
        message.success('Xóa thành công')
        client.resetStore()
      }
      else{
        message.error('Có lỗi xảy ra')
      }
    } catch (error) {
      message.error('Lỗi hệ thống!')
    }
  }

  const handleEdit = (id) => {
    history.push(`/user_service/edit/${id}`)
  }
  return (
    <>
      <h1 className={styles.title}>Dịch vụ</h1>
      <div className={styles.addButton}>
        <NavLink className={styles.navLink} to="user_service/add">
          Tạo mới
        </NavLink>
      </div>
      <Page isLoading={isLoading}>
        <div className={styles.search}></div>
        <table className={styles.table}>
          <tr className={styles.headTable}>
            <th>Loại dịch vụ</th>
            <th>Chung cư</th>
            <th>Người nhận dịch vụ</th>
            <th>Số điện thoại</th>
            <th>Ngày tạo</th>
            <th colSpan={2}>Action</th>
          </tr>
          {!userServices.loading &&
            state.userServices.length > 0 &&
            state.userServices.map((service, key) => {
              return (
                <tr key={key}>
                  <td>
                    <img
                      className={styles.iconType}
                      src={`${service?.topic?.icon}`}
                    />
                    <span className={styles.typeService}>{service?.topic?.name}</span>
                  </td>
                  <td>{service?.apartment_block?.name}</td>
                  <td>{service?.user?.name}</td>
                  <td>{service?.user?.phone_number}</td>
                  <td>
                    {dayjs(new Date(service.created_at)).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </td>
                  <td className={styles.actionIcon}>
                    <EditOutlined
                      onClick={() => handleEdit(service.id)}
                      style={{ color: "darkcyan" }}
                    />
                  </td>
                  <td className={styles.actionIcon}>
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() => handleDeleteUserService(service.id)}
                      />
                  </td>
                </tr>
              );
            })}
        </table>
        <Pagination total={state.total_count} />
      </Page>
    </>
  );
};

export default UserService;
