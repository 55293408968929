import {
    useLocation,
    useHistory
  } from "react-router-dom";
  import queryString from 'query-string'
  
  const usePagination = (props) => {
    const {limit} = props
    const location = useLocation()
    const history = useHistory()
    
    let params = queryString.parse(location.search)
    let offset = params.offset ? parseInt(params.offset) : 0
    let pathname = location.pathname

    const changePage = (page, pageSize) => {
      let offset = (page - 1) * limit 
      let query = queryString.stringify({...params, offset: offset, limit: limit})
      history.push(`${location.pathname}?${query}`)
    }
  
    return {
      offset,
      limit,
      pathname,
      changePage
    }
  }
  
  export default usePagination