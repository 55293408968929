import styles from "./User.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import UserGL from "../../Graphql/User/User";
import Page from "../../components/Page/Page";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/BaseComponents/Pagination";
import { Select, Button, Input, message } from "antd";
import { UndoOutlined, DeleteOutlined } from "@ant-design/icons";
import { TYPE_APARTMENT } from "../../constants/constants";
import { client } from "../../Graphql/client";
const { Search } = Input;
const initialState = {
  limit: 10,
  total_count: 0,
  users: [],
};

const User = () => {
  const [state, setState] = useState(initialState);
  const { offset, limit } = usePagination({ limit: state.limit });
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [queryFilter, setQueryFilter] = useState("");

  const res = useQuery(UserGL.getList({ limit: limit, offset, query }));
  const [deleteUser] = useMutation(UserGL.delete());

  useEffect(() => {
    if (res.data) {
      setState((prev) => {
        return {
          ...prev,
          users: res.data.users,
          total_count: res.data.users_aggregate.aggregate.count,
        };
      });
      setIsLoading(false);
    }
  }, [res.data]);
  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDeleteUser = async (user_id) => {
    try {
      const res = await deleteUser({ variables: { id: user_id } });
      if (res.data) {
        message.success("Xóa thành công!");
        return client.resetStore();
      } else {
        message.error("Có lỗi xảy ra!");
      }
    } catch (error) {
      message.error("Người dùng đang trong một dịch vụ nào đó!");
    }
  };

  const onSearch = () => {
    let query = `where: {_or: [
      {name: {_ilike: "%${search}%"}}, 
      {phone_number: {_ilike: "%${search}%"}}
    ]}`;
    setQuery(query);
  };

  const onChangeSelect = (e) => {
    let query = `where: {housing_type: {_eq: "${e}"}}`;
    setQueryFilter(query);
  };
  const onSearchFilter = () => {
    setQuery(queryFilter);
  };
  const onReset = () => {
    setQuery("");
  };
  return (
    <>
      <h1 className={styles.title}>Người dùng</h1>
      <Page isLoading={isLoading}>
        <div className={styles.search}>
          <div>
            <Search
              placeholder="Số điện thoại hoặc tên"
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: 400, marginBottom: "30px" }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Loại căn hộ:
            </div>
            <Select
              style={{ width: 120 }}
              // onChange={handleChange}
              onChange={onChangeSelect}
              options={TYPE_APARTMENT}
            />
          </div>
          <Button
            onClick={onSearchFilter}
            style={{ marginBottom: "20px" }}
            type="primary"
          >
            Tìm kiếm
          </Button>
          <Button
            onClick={onReset}
            style={{
              marginBottom: "20px",
              border: "2px solid rgb(241, 237, 237)",
              marginLeft: "20px",
            }}
            type=""
          >
            Đặt lại <UndoOutlined />
          </Button>
        </div>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headTable}>
              <th>Ảnh</th>
              <th>Tên</th>
              <th>SĐT/Email</th>
              <th>Mã căn hộ</th>
              <th>Chung cư</th>
              <th>Loại căn hộ</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {state &&
              state.users &&
              state.users.map((user, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <img className={styles.avatar} src={user.avatar} />
                    </td>
                    <td>{user.name}</td>
                    <td>
                      {user.phone_number} {user.email}
                    </td>
                    <td>{user.apartment}</td>
                    <td>{user?.apartment_block?.name}</td>
                    <td>
                      {user.housing_type === "HOUSING_TYPE_OWNER"
                        ? "Chính chủ"
                        : user.housing_type === "HOUSING_TYPE_RENTAL"
                        ? "Cho thuê"
                        : " "}
                    </td>
                    <td className={styles.actionIcon}>
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() => handleDeleteUser(user.id)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Pagination total={state.total_count} />
      </Page>
    </>
  );
};

export default User;
