import { useEffect, useState } from "react";
import Input from "../../../components/BaseComponents/Input/Input";
import styles from "./AddApartment.module.scss";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useMutation } from "@apollo/client";
import ApartmentQL from "../../../Graphql/Apartment/Apartment";
import { client } from "../../../Graphql/client";

const initState = {
  name: "",
  address: "",
  apartment_number: "",
  avatar: "",
  owner_by: "",
  manage_by: "",
};
const AddApartment = () => {
  const [state, setState] = useState(initState);
  const history = useHistory();
  const [addApartment] = useMutation(
    ApartmentQL.addApartment()
  );

  useEffect(()=> {
    const unique_id = uuid();
    setState((prev) => {
      return{
        ...prev,
        id: unique_id
      }
    })
  },[])
  const handleAddApartment = async (data) => {
    try {
      const res = await addApartment({
        variables: {
          id: data.id,
          manage_by: data.manage_by,
          name: data.name,
          owner_by: data.owner_by,
          avatar: data.avatar,
          apartment_number: data.apartment_number ? data.apartment_number : null,
          address: data.address,
          code: ""
        },
      });
      if(res.data){
        client.resetStore();
        message.success("Thêm chung cư thành công!")
        handleCancel();
      }
      else{
        message.error("Có lỗi xảy ra!")
      }
    } catch (error) {
      message.error("Lỗi")
    }
    
  };
  const handleChange = ({ name, value }) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleCancel = () => {
    history.goBack();
  };
  return (
    <>
      <h1 className={styles.title}>Tạo mới căn hộ</h1>
      <div className={styles.addContent}>
        <Input
          label="Tên căn hộ: "
          onChange={handleChange}
          type="text"
          required={true}
          name="name"
          value={state.name}
        />
        <Input
          label="Địa chỉ: "
          onChange={handleChange}
          type="text"
          required={true}
          name="address"
          value={state.address}
        />
        <Input
          label="Số căn hộ: "
          onChange={handleChange}
          type="number"
          name="apartment_number"
          value={state.apartment_number}
        />
        <Input
          label="Ảnh : "
          onChange={handleChange}
          type="text"
          name="avatar"
          value={state.avatar}
        />
        <Input
          label="Chủ sở hữu: "
          onChange={handleChange}
          type="text"
          name="owner_by"
          value={state.owner_by}
        />
        <Input
          label="Quản lí: "
          onChange={handleChange}
          type="text"
          name="manage_by"
          value={state.manage_by}
        />
        <div className={styles.btn}>
          <Button
            onClick={() => handleAddApartment(state)}
            type="primary"
            style={{ marginRight: "20px" }}
          >
            Thêm
          </Button>
          <Button onClick={handleCancel}>Hủy bỏ</Button>
        </div>
      </div>
    </>
  );
};

export default AddApartment;
